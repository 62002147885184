<template>
  <div class="d-flex">
    <!-- 搜尋框 -->
    <v-text-field
      v-model="search"
      density="compact"
      label="Search"
      prepend-inner-icon="fa-solid fa-magnifying-glass"
      variant="solo-filled"
      flat
      hide-details
      single-line
      class="searchInput me-2"
      @change="getSearch"
    />
  </div>
</template>

<script setup>
const search = ref('')

const emit = defineEmits(['searchFn'])
const getSearch = () => {
  emit('searchFn', search.value)
}
</script>

<style lang="scss" scoped>
.searchInput {
  width: 300px;
}
:deep(.v-field__prepend-inner > .v-icon) {
  opacity: 1;
  font-size: 14px;
  margin-right: 5px;
}
</style>
